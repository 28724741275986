<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            codeGroupCd="VENDOR_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorClassCd"
            label="업체분류"
            v-model="searchParam.vendorClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            label="도급업체명"
            name="vendorName"
            v-model="searchParam.vendorName">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="vendorTable"
      title="도급업체 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="vendorCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendor-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'vendorClassName',
            field: 'vendorClassName',
            label: '업체분류',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'plantNames',
            field: 'plantNames',
            label: '관련사업장',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        plantCd: null,
        vendorClassCd: null,
        vendorName: '',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.cim.vendor.list.url;
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['vendorTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '도급업체를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
